<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          v-if="reseller.items"
          no-body
          class="card-company-table"
        >
          <b-table
            :items="reseller.items"
            responsive
            :fields="reseller.fields"
            class="mb-0"
          >
            <!-- reseller -->
            <template #cell(reseller)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.title }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.item.subtitle }}
                  </div>
                </div>
              </div>
            </template>

            <!-- category -->
            <template #cell(category)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-1"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    :icon="data.item.avatarIcon"
                  />
                </b-avatar>
                <span>{{ data.item.avatarTitle }}</span>
              </div>
            </template>

            <!-- sales -->
            <template #cell(sales)="data">
              <div class="d-flex align-items-center">
                <span class="font-weight-bolder mr-1">{{ data.item.sales }}</span>
                <feather-icon
                  :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
                  :class="data.item.loss ? 'text-danger':'text-success'"
                />
              </div>
            </template>

            <!-- views -->
            <template #cell(omzet)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{ data.item.omzet }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BTable, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
  },

  data() {
    return {
      reseller: {
        items: [
          {
            no: '#1',
            avatarIcon: 'CoffeeIcon',
            avatarTitle: 'Grocery',
            loss: true,
            sales: '89',
            subtitle: 'rnbcoliving@gmail.com',
            title: 'Martha Simanjuntak',
            omzet: 'Rp. 28.900.000',
            joinDate: '17 Jun 2019',
          },
          {
            no: '#2',
            avatarIcon: 'MonitorIcon',
            avatarTitle: 'Technology',
            loss: false,
            sales: '71',
            subtitle: 'sittirabiah194@gmail.com',
            title: 'Siti Rabiah',
            omzet: 'Rp. 20.350.000',
            joinDate: '9 Sep 2019',
          },
          {
            no: '#3',
            avatarIcon: 'CoffeeIcon',
            avatarTitle: 'Grocery',
            loss: true,
            sales: '68',
            subtitle: 'uchanerlansyah@gmail.com',
            title: 'Euis Yulia Susanti',
            omzet: 'Rp. 12.780.000',
            joinDate: '3 Jan 2020',
          },
          {
            no: '#4',
            avatarIcon: 'CoffeeIcon',
            avatarTitle: 'Grocery',
            loss: true,
            sales: '52',
            subtitle: 'rindapfickr@gmail.com',
            title: 'Rinda Mandasari Samosir',
            omzet: 'Rp. 10.330.000',
            joinDate: '9 Aug 2020',
          },
          {
            no: '#5',
            avatarIcon: 'TagIcon',
            avatarTitle: 'Clothing',
            loss: false,
            sales: '69',
            subtitle: 'yanisp03@gmail.com',
            title: 'Kastriyani Sugiati Putri',
            omzet: 'Rp. 7.690.000',
            joinDate: '14 Mar 2021',
          },
          // {
          //   no: "#6",
          //   avatarIcon: "TagIcon",
          //   avatarTitle: "Clothing",
          //   loss: false,
          //   sales: "68",
          //   subtitle: "achi.hartoyo@gmail.com",
          //   title: "Achi Hartoyo",
          //   omzet: "Rp. 5.980.000",
          //   joinDate: "1 Jun 2019"
          // },
          // {
          //   no: "#7",
          //   avatarIcon: "TagIcon",
          //   avatarTitle: "Clothing",
          //   loss: false,
          //   sales: "68",
          //   subtitle: "roelly87@gmail.com",
          //   title: "Choirul Huda",
          //   omzet: "Rp. 5.980.000",
          //   joinDate: "1 Jun 2019"
          // },
          // {
          //   no: "#8",
          //   avatarIcon: "TagIcon",
          //   avatarTitle: "Clothing",
          //   loss: false,
          //   sales: "68",
          //   subtitle: "bimawidjanata127@gmail.com",
          //   title: "Bima Widjanata Suwaji",
          //   omzet: "Rp. 5.980.000",
          //   joinDate: "1 Jun 2019"
          // },
          // {
          //   no: "#9",
          //   avatarIcon: "TagIcon",
          //   avatarTitle: "Clothing",
          //   loss: false,
          //   sales: "68",
          //   subtitle: "heninurmala18@gmail.com",
          //   title: "Heni Nurmala",
          //   omzet: "Rp. 5.980.000",
          //   joinDate: "1 Jun 2019"
          // },
          // {
          //   no: "#10",
          //   avatarIcon: "TagIcon",
          //   avatarTitle: "Clothing",
          //   loss: false,
          //   sales: "68",
          //   subtitle: "maya.manafrio@gmail.com",
          //   title: "Maya Maulia",
          //   omzet: "Rp. 5.980.000",
          //   joinDate: "1 Jun 2019"
          // },
        ],
        fields: [
          { key: 'no', label: 'NO' },
          { key: 'reseller', label: 'RESELLER' },
          // { key: 'category', label: 'CATEGORY' },
          { key: 'joinDate', label: 'JOIN DATE' },
          { key: 'omzet', label: 'OMZET' },
          { key: 'sales', label: 'SALES' },
        ],
      },
    }
  },
}
</script>
